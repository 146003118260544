<template>
  <Layout>
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" :variant="alertvariant" dismissible>
        <h5>Success!</h5>
        {{ alertMsg }}
      </b-alert>
    </div>
    <PageHeader :title="title" :items="items"/>

    <form @submit.prevent="submitPrincipleForm" method="post">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 row">
                  <div class="mb-3 col-md-4">

                    <label class="form-label" for="formrow-corporateLogo-input">Principal Logo</label>
                    <div class="file-upload-form">
                      Choose File
                      <input type="file" @change="previewImage" class="uploadInput" accept="image/*">
                    </div>

                  </div>
                  <div class="image-preview col-md-3" v-if="imageData.length > 0">
                    <img class="preview rounded-circle" :src="imageData">
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="mb-3 col-md-6">
                  <label class="form-label" for="formrow-principleName-input">Principal Name *</label>
                  <input type="text" class="form-control" id="formrow-principleName-input" name="Principle"
                         v-model="principleName"
                         :class="{
                            'is-invalid': submitted && $v.principleName.$error,
                          }">
                  <div
                      v-if="submitted && $v.principleName.$error"
                      class="invalid-feedback"
                  >
                          <span v-if="!$v.principleName.required"
                          >This value is required.</span
                          >
                  </div>
                </div>

                <div class="col-md-6 mb-3">
                  <label class="form-label" for="formrow-website-input">Website</label>
                  <input type="text" class="form-control" id="formrow-website-input" v-model="website">

                </div>
                <div class="col-md-6 mb-4">
                  <label class="form-label" for="input-contactNO">Contact NO.</label>
                  <input type="number" id="input-contactNO" class="form-control"
                         v-model="contactNO" :class="{
                                'is-invalid': submitted && $v.contactNO.$error,
                              }"
                  >
                  <div
                      v-if="submitted && $v.contactNO.$error"
                      class="invalid-feedback"
                  >
                         <span v-if="!$v.contactNO.maxLength"
                         >This value is too long. It should have 10 digits</span
                         >
                    <span v-if="!$v.contactNO.minLength"
                    >This value length is invalid. It should be
                              10 digits</span
                    >
                  </div>
                </div>
              </div>

              <div class=" row" style="float:right;">
                <b-spinner v-if="loading"
                           class="m-2 col-3"
                           variant="primary"
                           role="status"
                ></b-spinner>
                <button type="submit" v-if="!isEdit && $can('create principle')" class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                        :disabled="loading">Submit
                </button>
                <button type="submit" v-if="isEdit && $can('update principle')" class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                        :disabled="loading">Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {
  required, maxLength, minLength
} from "vuelidate/lib/validators";

export default {
  page: {
    title: "Add Principal",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Add Principal",
      items: [
        {
          text: "Principal",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],

      status: '',
      submitted: false,
      maxlen: "",
      disabled: true,
      imageData: '',
      encodeImage: '',
      showDismissibleAlert: false,
      alertvariant: "success",
      principleID: 0,
      isEdit: false,
      principleData: [],
      alertMsg: "Principal Registered Successfully!",
      loading: false,
      principleName: null,
      contactNO: "",
      website: "",
      adminName: "",
      adminEmailID: "",
      adminMobileNO: "",
    };
  },

  validations: {

    principleName: {
      required,
    },
    contactNO: {
      maxLength: maxLength(10),
      minLength: minLength(10),
    },
  },
  mounted() {

    if (this.$route.params.type == "edit") {
      if (this.$route.params.id) {
        sessionStorage.setItem('principleID', this.$route.params.id);
      }
    } else {
      sessionStorage.setItem('principleID', 0);
    }
    this.principleID = sessionStorage.getItem('principleID')
    if (this.principleID > 0) {
      this.getPrincipleDataByID();
      this.isEdit = true;

    }

  },
  methods: {
    submitPrincipleForm(e) {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let formData = new FormData();
        formData.append('principleID', this.principleID);
        formData.append('principleName', this.principleName);
        formData.append('website', this.website);
        formData.append('contactNO', this.contactNO);
        formData.append('document', this.encodeImage);
        e.preventDefault();
        let apiName = this.$loggedRole +"/addPrinciple";
        let routerPush = "principleBranchForm";
        if (this.isEdit == true) {
          apiName = this.$loggedRole +"/updatePrincipleForm";
          this.alertMsg = "Principle Updated Successfully!"
          routerPush = "principleTable";
        }

        this.axios.post(apiName, formData,
            {
              'Content-Type': 'multipart/form-data'
            })
            .then((result) => {
            this.loading = false;
              this.$swal({
                text: result.data.message,
                icon: "success",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000
              });
              setTimeout(() => {
                this.$router.push({name: routerPush});
              }, 2000);
            })
            .catch(function (error) {
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error",
              });
            });
      }
    },

    previewImage: function (event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imageData = e.target.result;
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
        this.encodeImage = input.files[0];
      }
    },

    getPrincipleDataByID() {
      this.axios.post(this.$loggedRole + "/getPrincipleDataByID",
          {'principleID': this.principleID})
          .then((result) => {
            this.principleData = result.data.data;
            this.imageData = this.principleData.image;
            this.website = this.principleData.website;
            this.contactNO = this.principleData.contactNO;
            this.principleName = this.principleData.principleName;
            this.adminEmailID = this.principleData.adminEmailID;
            this.adminMobileNO = this.principleData.adminMobileNO;
            this.adminName = this.principleData.adminName;
          });
    }
  },
  middleware: "authentication",
};
</script>
<style lang="scss" scoped>

img.preview {
  width: 100px;
  height: 100px;
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
  object-fit: contain;
  margin-top: 6px;
}

.uploadInput {
  position: absolute;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 24px;
  width: 100%;
}

.file-upload-form {
  padding: 5px 10px;
  background: #ececec;
  border: 1px solid #ececec;
  position: relative;
  color: black;
  border-radius: 2px;
  text-align: center;
  float: left;
  cursor: pointer;
}
</style>


